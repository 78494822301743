.photo-header {
  padding: 0.125rem 0.25rem;
}

.photo-header-location {
  flex-basis: 0;
}

.desktop-layout .photo-header-container:not(:hover):not(:focus-within) .photo-header-checkbox:not(.checked) {
  border: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}

.photo-header-checkbox.photo-checkbox.bolt-checkbox {
  margin-inline-end: 8px;
}
