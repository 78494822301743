/* Common: */
.bolt-messagecard.bolt-card.moj-banner,
.bolt-messagecard.bolt-card.quota-banner {
  border: 1px solid var(--palette-black-alpha-20);
  border-radius: 0.75rem;
  box-shadow: none;
}

.bolt-messagecard.bolt-card.banner:not(:last-of-type) {
  margin-bottom: 12px;
}

.bolt-messagecard.bolt-card.moj-banner .bolt-messagebar-content,
.bolt-messagecard.bolt-card.quota-banner .bolt-messagebar-content {
  padding: 12px;
}

.bolt-messagecard.bolt-card.moj-banner .bolt-messagebar-icons,
.bolt-messagecard.bolt-card.quota-banner .bolt-messagebar-icons {
  align-items: center;
}

/* MOJ banner: */
.bolt-messagecard.bolt-card.moj-banner .bolt-messagebar.notification-banner {
  background-color: rgba(var(--palette-primary-tint-30), 1);
}

.bolt-messagecard.bolt-card.moj-banner .star-highlighted-icon {
  fill: rgba(var(--palette-primary-shade-20), 1);
}

/* Quota banners: */
.bolt-messagecard.bolt-card.quota-banner .information-icon {
  fill: var(--palette-black-alpha-60);
}

.bolt-messagecard.bolt-card.quota-banner .warning-icon {
  fill: var(--status-warning-background);
}

.bolt-messagecard.bolt-card.quota-banner .storage-link {
  color: var(--communication-foreground);
  font-weight: 400;
}

.bolt-messagecard.bolt-card.quota-banner .storage-link:hover {
  background-color: transparent;
  text-decoration: underline;
}

.bolt-messagecard.bolt-card.quota-banner .upgrade-button {
  background-color: rgb(var(--palette-neutral-0));
  border: 1px solid var(--palette-black-alpha-20);
}

.bolt-messagecard.bolt-card.quota-banner.full {
  border-color: rgb(238, 172, 178);
}

.bolt-messagecard.bolt-card.quota-banner.full .bolt-messagebar.notification-banner {
  background-color: rgb(253, 243, 244);
  color: rgba(0, 0, 0, 0.9);
}
