/**
 * Specific AllPhotos command styles.
 */
.all-photos-view .page-command-bar {
  z-index: 2;
}

.all-photos-view .photo-notification-group:has(> .banner) {
  padding-top: 16px;
}

.photo-sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

@media screen and (min-width: 320px) {
  .photo-sticky-header.sticky-background {
    background: linear-gradient(
      90deg,
      rgb(var(--background-color-stop-2)) 0%,
      rgb(var(--background-color-stop-5)) 70%,
      rgb(var(--background-color-stop-4)) 100%
    );
  }
}

@media screen and (min-width: 640px) {
  .photo-sticky-header.sticky-background {
    background: linear-gradient(
      90deg,
      rgb(var(--background-color-stop-2)) 0%,
      rgb(var(--background-color-stop-2)) 20%,
      rgb(var(--background-color-stop-5)) 65%
    );
  }

  @media screen and (max-height: 639px) {
    .photo-sticky-header.sticky-background {
      background: linear-gradient(
        90deg,
        rgb(var(--background-color-stop-3)) 0%,
        rgb(var(--background-color-stop-3)) 15%,
        rgb(var(--background-color-stop-1)) 75%,
        rgb(var(--background-color-stop-4)) 100%
      );
    }
  }
}

@media screen and (min-width: 1024px) {
  .photo-sticky-header.sticky-background {
    background: linear-gradient(90deg, rgb(var(--background-color-stop-3)) 0%, rgb(var(--background-color-stop-4)) 65%);
  }

  @media screen and (max-height: 639px) {
    .photo-sticky-header.sticky-background {
      background: linear-gradient(
        90deg,
        rgb(var(--background-color-stop-3)) 0%,
        rgb(var(--background-color-stop-3)) 15%,
        rgb(var(--background-color-stop-1)) 70%,
        rgb(var(--background-color-stop-4)) 100%
      );
    }
  }
}

@media screen and (min-width: 1440px) {
  .photo-sticky-header.sticky-background {
    background: linear-gradient(
      90deg,
      rgb(var(--background-color-stop-3)) 0%,
      rgb(var(--background-color-stop-4)) 57%,
      rgb(var(--background-color-stop-4)) 75%,
      rgb(var(--background-color-stop-2)) 100%
    );
  }

  @media screen and (max-height: 639px) {
    .photo-sticky-header.sticky-background {
      background: linear-gradient(
        90deg,
        rgb(var(--background-color-stop-3)) 0%,
        rgb(var(--background-color-stop-3)) 15%,
        rgb(var(--background-color-stop-1)) 65%,
        rgb(var(--background-color-stop-2)) 100%
      );
    }
  }
}

.layout-menu .bolt-menuitem-cell-state {
  color: var(--status-info-foreground);
}

/**
 * Timeline styles
 */
.all-photos-view .photo-scrubber {
  bottom: 0;
  position: absolute;
  top: 0;
}

html[dir="ltr"] .all-photos-view .photo-scrubber {
  right: 0;
}

html[dir="rtl"] .all-photos-view .photo-scrubber {
  left: 0;
}

.all-photos-view .photo-scrubber-visible,
.all-photos-view .photo-scrubber:hover {
  z-index: 2;
}

/**
 * Carousel styles
 */
.bolt-carousel-item.light-background {
  background-color: rgb(255, 255, 255);
}

.bolt-carousel-item.light-background .tile-overlay {
  background: none;
  color: rgb(37, 36, 35);
}

.bolt-carousel-item.standard-size .bolt-carousel-image {
  height: 90px;
  margin-top: 28px;
  width: 200px;
}

.for-you-animated {
  max-height: 298px;
  overflow-y: clip;
  transition: max-height 367ms cubic-bezier(0, 0, 0, 1);
}

.for-you-animated.for-you-hidden {
  max-height: 0;
}
