.tile-layout {
  touch-action: pan-y;
}

.layout-range-select {
  background-color: rgba(var(--palette-primary-tint-10), 0.2);
  border: 1px solid var(--focus-border-color);
  pointer-events: none;
  z-index: 1;
}
