.photo-navigation {
  width: 4.25rem;
  z-index: 3;
}

.photo-navigation.expanded {
  width: 17.5rem;
}

.photo-navigation.expanded .toggle-navigation svg {
  opacity: 0;
  transition: all ease-in-out 0.2s;
  visibility: hidden;
}

.photo-navigation.expanded:hover .toggle-navigation svg,
.bolt-focus-visible .photo-navigation.expanded .toggle-navigation:focus svg {
  opacity: 1;
  visibility: visible;
}

.add-new-button.bolt-button {
  background: linear-gradient(128.84deg, rgba(var(--palette-primary), 1) 20.46%, rgb(60, 69, 171) 72.3%);
  color: white;
  height: 36px;
  padding: 0 16px 0 10px;
  /* TODO: (akisnejuhasz) this button is still in progress, hiding it for now, but reserving space for it */
  visibility: hidden;
  opacity: 0;
}

.add-new-button.bolt-button.collapsed {
  max-width: 36px;
  padding: 0;
  width: 100%;
}

.add-new-button.bolt-button:not(.icon-only) .addition-icon,
.add-new-button.bolt-button.icon-only .addition-icon {
  height: 14px;
  width: 14px;
}

.add-new-button.bolt-button:not(.icon-only) .addition-icon {
  margin-inline-end: 11px;
}

.add-new-button.bolt-button span,
.navigation-link.bolt-button span {
  /* to match ODC styles: */
  padding-bottom: 1px;
}

.navigation-item {
  min-height: 2.25rem;
}

.navigation-link.bolt-button {
  flex-shrink: 1;
  margin-inline-start: 0.938rem;
  padding: 0;
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.25rem;
}

.collapsed .navigation-link.bolt-button {
  margin-inline-end: 0.938rem;
  padding-inline-end: 0.5rem;
}

.collapsed .navigation-link.bolt-button svg {
  margin: 0;
}

.navigation-link.selected .left-icon {
  color: var(--communication-background);
}

.navigation-link-text {
  font-weight: 400;
}

.navigation-link.selected .navigation-link-text {
  font-weight: 600;
}

.toggle-navigation.bolt-button,
.group-header {
  height: 36px;
  /* to match ODC styles: */
  margin-inline-start: 0.938rem;
  padding-inline-start: 0.5rem;
}

.toggle-navigation.bolt-button.subtle:hover {
  background-color: transparent;
}

.selection-banner {
  background-color: var(--palette-black-alpha-20);
  border-radius: 1rem;
  display: flex;
  height: 1rem;
  inset-inline-start: -12px;
  position: absolute;
  width: 0.188rem;
}

.navigation-link.selected .selection-banner {
  background-color: var(--communication-background);
}

.navigation-link:not(.selected):not(:hover) .selection-banner {
  display: none;
}

.group-header {
  border: 1px solid transparent;
  position: relative;
}

.group-header .separator-line {
  background-color: var(--palette-black-alpha-20);
  border-radius: 1px;
  height: 1px;
  inset-inline-start: 6px;
  position: absolute;
  top: 11px;
  width: 24px;
}

/**
 * Customize specific elements
 */
.purchase-label {
  color: var(--palette-black-alpha-60);
  line-height: 1.125rem;
}

.photo-navigation .premium-button.bolt-button {
  background-color: rgb(var(--palette-neutral-0));
  border-color: var(--palette-black-alpha-20);
  border-width: 1.5px;
  box-shadow:
    0 1px 2px rgb(0 0 0 / 14%),
    0 0 2px rgb(0 0 0 / 12%);
  color: var(--communication-foreground);
  font-weight: 600;
  justify-content: center;
}

.photo-navigation .premium-button.collapsed.bolt-button {
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
}

.buy-storage.bolt-button,
.buy-storage.bolt-button:hover {
  background-color: var(--palette-error-10);
}

.photo-navigation .nearing-button.bolt-button {
  background-color: var(--status-warning-background);
  border-color: transparent;
  color: white;
}

.photo-navigation .full-button.bolt-button {
  background-color: var(--status-error-background);
  border-color: transparent;
  color: white;
}

.account-details .purchase-border {
  border: 1px solid var(--palette-black-alpha-20);
}

/* to match ODC styles: */
.account-details .quota-icon-label-container {
  margin-bottom: 0.875rem;
}

.account-details .quota-icon {
  color: var(--palette-error-10);
  height: 1.25rem;
  width: 1.25rem;
}

.collapsed .account-details .quota-icon-label {
  display: none;
}

.account-details .quota-used-percentile {
  background-color: var(--communication-foreground);
  height: 0.125rem;
  min-width: 2px;
}

.account-details.nearing .quota-used-percentile {
  background-color: var(--status-warning-background);
}

.account-details.full .quota-used-percentile {
  background-color: var(--status-error-background);
}

.account-details .quota-unused-percentile {
  background-color: var(--palette-black-alpha-20);
  height: 0.125rem;
}

.account-details.nearing .quota-used-text {
  color: var(--status-warning-background);
}

.account-details.full .quota-used-text {
  color: var(--palette-error-10);
}

.account-details .free-space.bolt-button {
  border-color: var(--communication-foreground);
  color: var(--communication-foreground);
}

/**
 * Navigation panel styles
 */
.navigation-callout {
  margin-top: 3rem;
}

.navigation-callout .photo-navigation {
  flex-grow: 1;
}

.navigation-callout .bolt-callout-content {
  background-color: rgba(var(--palette-neutral-4), 1);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  box-shadow:
    0 0 2px rgba(0, 0, 0, 0.12),
    0 4px 8px rgba(0, 0, 0, 0.14);
  margin-bottom: 16px;
}
