.photo-tile {
  background-color: var(--palette-black-alpha-20);
  display: inline-flex;
  overflow: hidden;
  -webkit-touch-callout: none;
}

.photo-tile:hover,
.photo-tile:focus-within {
  box-shadow:
    0 0.125rem 0.25rem rgb(0 0 0 / 14%),
    0 0 0.125rem rgb(0 0 0 / 12%);
}

.photo-tile .favorite-button {
  position: absolute;
  left: 0.5rem;
  bottom: 0.5rem;
  color: rgb(255, 255, 255);
}

.hover-badge-text {
  display: inline-flex;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  max-width: 0px;
  padding-left: 0px;
  -webkit-transition: max-width 2s;
  transition: max-width 2s;
  transition: padding-left 0.5s;
}

.photo-tile:hover .hover-badge-text,
.photo-tile:focus-within .hover-badge-text {
  max-width: 300px;
  padding: 0px 4px;
}

.photo-tile .photo,
.photo-tile .video {
  align-items: center;
  border: 0;
  display: inline-flex;
  justify-content: center;
  opacity: 1;
  outline: none;
  overflow: hidden;
  padding: 0;
  transition: opacity 300ms ease-out 0ms;
}

.photo-tile .photo.inactive {
  opacity: 0;
  transition: opacity 300ms ease-in 0ms;
}

.photo-tile.proposed-selection .tile-overlay {
  background-color: rgba(var(--palette-primary-shade-30), 0.4);
}

.photo-tile.show-gradient .tile-overlay,
.photo-tile:not(.proposed-selection):not(.no-gradient):hover .tile-overlay,
.photo-tile:not(.proposed-selection):not(.no-gradient):focus-within .tile-overlay,
.photo-tile.selected:not(.proposed-selection):not(.no-gradient) .tile-overlay {
  background: linear-gradient(rgba(0, 0, 0, 0) 0% 60%, rgba(0, 0, 0, 0.501961) 90% 100%);
}

.phototile-dropdown svg {
  fill: rgb(var(--palette-neutral-100));
}

.phototile-dropdown {
  max-width: 8rem;
}

.photo-tile-name,
.placeholder-name {
  /**
   * NOTE: This padding is contained within a fixed px layout size and thus
   * needs a fixed size to meet requirements.
   */
  margin-bottom: 10px;
}
