/*
  Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license
*/
.ms-Icon {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: flex;
  font-family: "FabricMDL2Icons";
  font-style: normal;
  font-weight: normal;
}

.ms-Icon--AddTo:before {
  content: "\ECC8";
}
.ms-Icon--BingLogo:before {
  content: "\EB6B";
}
.ms-Icon--Blocked12:before {
  content: "\F62E";
}
.ms-Icon--Blocked2:before {
  content: "\ECE4";
}
.ms-Icon--Chat:before {
  content: "\E901";
}
.ms-Icon--CircleAddition:before {
  content: "\F2E3";
}
.ms-Icon--CircleAdditionSolid:before {
  content: "\F2E4";
}
.ms-Icon--CommentAdd:before {
  content: "\F2B3";
}
.ms-Icon--Diamond:before {
  content: "\ED02";
}
.ms-Icon--EmojiDisappointed:before {
  content: "\EA88";
}
.ms-Icon--EmojiNeutral:before {
  content: "\EA87";
}
.ms-Icon--Heart:before {
  content: "\EB51";
}
.ms-Icon--PhotoCollection:before {
  content: "\E7AA";
}
.ms-Icon--PictureFill:before {
  content: "\F523";
}
.ms-Icon--PlaySolid:before {
  content: "\F5B0";
}
.ms-Icon--RecycleBin:before {
  content: "\EF87";
}
.ms-Icon--Send:before {
  content: "\E724";
}
.ms-Icon--ZoomToFit:before {
  content: "\F649";
}
