.photo-view-header {
  color: var(--text-primary-color);
  height: 3rem;
  z-index: 3;
}

.header-button.bolt-button {
  border-radius: 0;
  min-height: 3rem;
  min-width: 3rem;
}

.header-button.app-launcher-button {
  /* to match ODC styles: */
  margin-inline-end: 0.438rem;
}

.photo-view-header .header-button {
  background-color: transparent;
  color: var(--text-primary-color);
}

.photo-view-header .header-button:hover,
.photo-view-header .header-button:hover svg {
  color: rgba(var(--palette-primary), 1);
}

.photo-view-header .header-mecontrol-tab:hover {
  background-color: var(--palette-black-alpha-6);
}

.photo-view-header .header-mecontrol-tab:focus {
  background-color: var(--palette-black-alpha-30);
}

.photo-view-header .header-button .svg-icon {
  color: var(--text-primary-color);
}

.photo-view-header .search-form {
  background-color: var(--background-color);
  border-radius: 4px;
}

.photo-view-product-title {
  border: 1px solid transparent;
  border-radius: 0;
  color: var(--text-primary-color);
  font-size: 1rem;
  /* to match ODC styles: */
  padding-bottom: 1px;
}

.photo-view-product-title.bolt-link:hover,
.photo-view-product-title.bolt-link:focus {
  color: var(--text-primary-color);
  text-decoration: none;
}

.photo-view-product-title.bolt-link:focus {
  border: 1px solid var(--text-primary-color);
}

/* to match ODC styles: */
.desktop-layout .photo-view-product-title,
.tablet-layout .photo-view-product-title {
  margin-inline-end: 68px;
}

.photo-header-profile {
  height: 3rem;
  width: 3rem;
}

.photo-header-profile button {
  border-color: var(--text-primary-color) !important;
  color: var(--text-primary-color) !important;
}

.onedrive-application-icon {
  height: 3rem;
  width: 3rem;
}

.photo-view-header .mectrl_topHeader {
  height: 3rem;
}

.photo-view-header .mectrl_topHeader .mectrl_profilepic {
  height: 2rem;
  width: 2rem;
}

.photo-view-header .search-bar-container {
  max-width: 468px;
  width: 100%;
}

/* to match ODC styles: */
@media screen and (max-width: 879px) {
  .photo-view-header .search-bar-container {
    max-width: 304px;
  }

  .photo-view-header .search-bar-container .search-bar {
    min-width: 216px;
  }
}

.search-bar {
  background-color: var(--background-color);
  border-radius: 2rem;
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.14),
    0 0 2px rgba(0, 0, 0, 0.12);
  z-index: 3;
}

.search-bar:hover,
.search-bar.active {
  box-shadow:
    0 2px 4px rgba(0, 0, 0, 0.14),
    0 0 2px rgba(0, 0, 0, 0.12);
}

.upload-target.background-active .search-bar {
  background-color: rgba(var(--palette-neutral-0), 0.5);
}

.desktop-layout .search-bar,
.tablet-layout .search-bar {
  min-width: 400px;
  width: 100%;
}

.search-bar .search-form {
  padding-inline-end: 0.125rem;
}

.search-bar .search-text-field .bolt-button {
  border-radius: 50%;
}

.search-bar form:not(.basic-search) .search-text-field .bolt-button:last-child {
  margin-inline-end: 0.125rem;
}

.mobile-layout .search-bar.active {
  left: 0;
  right: 0;
  top: 0;
  transform: translateY(35%);
}
