/**
 * Overlay styles
 */
.tile-overlay {
  border: 0 solid rgb(var(--palette-neutral-2));
  padding: 0.25rem;
}

.tile-overlay-container:focus-within .tile-overlay,
.tile-overlay-container.selected .tile-overlay {
  border-width: 0.25rem;
  padding: 0;
}

.tile-overlay-container:focus-within .tile-overlay.simple-overlay {
  border-width: 0.125rem;
  border-color: var(--focus-border-color);
  padding: 0.125rem;
}

.tile-overlay-container:focus-within .tile-overlay.limited-overlay-focus:focus-within {
  border-color: transparent;
}

/**
 * Overlay border styles
 */
.tile-overlay-border {
  border: 0.125rem solid transparent;
}

.tile-overlay-container.round .tile-overlay,
.tile-overlay-container.round .tile-overlay-border {
  border-radius: 50%;
}

.tile-overlay-container.selected .tile-overlay-border {
  border-color: var(--palette-primary-darken-10);
}

.tile-overlay-container:focus-within .tile-overlay-border {
  border-color: var(--focus-border-color);
}
