.pivot-button.bolt-button.bolt-link-button,
.pivot-button.bolt-button.bolt-link-button.primary {
  color: var(--text-primary-color);
  padding: 0.25rem;
}

.pivot-button.bolt-button.bolt-link-button:not(:first-of-type) {
  margin-left: 1rem;
}

.pivot-button.bolt-button.bolt-link-button.primary,
.pivot-button.bolt-button.bolt-link-button:hover {
  background-color: transparent;
}

.pivot-button.bolt-button.bolt-link-button.primary::after,
.pivot-dropdown .bolt-button::after {
  background-color: rgba(var(--palette-primary), 1);
  border-radius: 0.25rem;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  width: 100%;
}

.pivot-dropdown .bolt-button::after {
  left: 10px;
  width: 55%;
}

.bolt-focus-visible .pivot-button.bolt-button.bolt-focus-treatment:focus {
  border-color: var(--palette-black-alpha-100);
}
