.message-center-portal {
  z-index: 100;
}

.message-card {
  width: 22rem;
}

.message-card.close-animation {
  margin: 0;
  max-height: 0 !important;
  opacity: 0.2;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 0.5s;
}

.message-card-title {
  overflow-wrap: anywhere;
}

.message-card .error-icon {
  color: var(--status-error-foreground);
}

.message-card .success-icon {
  color: var(--status-success-text);
}

.message-card .information-icon {
  color: var(--status-warning-foreground);
}

.message-card-overlay {
  left: 0;
  right: 0;
}

.message-card-progress {
  background-color: rgb(var(--palette-neutral-10));
  height: 2px;
}

.message-card-completed {
  background-color: var(--text-primary-color);
}
