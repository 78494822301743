.background-layer-shell {
  background-position: center;
  background-size: cover;
}

.background-layer-shell .background-layer-overlay {
  background-color: rgba(var(--palette-neutral-0), 0.6);
}

body.embedded .standard-view {
  background: transparent;
  background-color: transparent;
}

/**
 * Modifications to the UX when the shell background images are active
 */
.shell-container.background-active .photo-navigation,
.shell-container.background-active .photo-view-header,
.shell-container.background-active .page-command-bar,
.shell-container.background-active .page-command-bar .separator-line-right,
.shell-container.background-active .photo-scrubber,
.shell-container.background-active .standard-view,
.shell-container.background-active .standard-view .bolt-link,
.shell-container.background-active .unnamed-people-container,
.shell-container.background-active .unnamed-cover {
  background: transparent;
  border-color: transparent;
}

.shell-container.background-active .create-album-tile.bolt-button,
.shell-container.background-active .create-album-tile.bolt-button:hover,
.shell-container.background-active .explore-tag-pill-button,
.shell-container.background-active .page-command-bar-raised,
.shell-container.background-active .photo-navigation .premium-button.bolt-button,
.shell-container.background-active .photo-view-header .search-form,
.shell-container.background-active .search-bar,
.shell-container.background-active .unnamed-pill {
  background-color: rgba(var(--palette-neutral-0), 0.85);
}

.shell-container.background-active .navigation-link.selected .left-icon,
.shell-container.background-active .photo-navigation .premium-button.bolt-button,
.shell-container.background-active .photo-scrubber-date,
.shell-container.background-active .search-form ::-webkit-input-placeholder,
.shell-container.background-active .search-icon,
.shell-container.background-active .standard-view .bolt-link {
  color: var(--text-primary-color);
}

.shell-container.background-active .photo-scrubber-bullet,
.shell-container.background-active .photo-zoom-slider .bolt-slider-thumb,
.shell-container.background-active .photo-zoom-slider .bolt-slider-track,
.shell-container.background-active .selection-banner {
  background-color: var(--text-primary-color);
}

.shell-container.background-active .photo-view-header .header-button:hover {
  background-color: var(--palette-black-alpha-6, rgba(0, 0, 0, 0.06));
}

/**
 * Modifications to the UX when the content is embedded.
 */
body.embedded,
.embedded .photo-view-content,
.embedded .photo-view-details,
.embedded .photo-view-titlebar {
  background: transparent;
  background-color: transparent;
}

/**
 * Styles for general frame elements.
 */
.help-panel {
  background-color: rgb(var(--help-panel-background));
}
