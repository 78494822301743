.photo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: transparent;
  object-fit: cover;
  object-position: center;
  outline: none;
  z-index: 0;
}

.photo-transition {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  outline: none;
  position: absolute;
  transition: visibility 0ms ease 300ms;
}

.photo-contain {
  background-size: contain;
  object-fit: contain;
}

.photo.visible {
  opacity: 1;
  transition: opacity 300ms ease 0ms;
}

.photo.portrait-mode {
  background-position: center 25%;
  object-position: center 25%;
}

.photo.photo-failed {
  background-position: center;
  background-size: auto;
}

.photo.fade-in {
  animation: fadeIn 300ms;
}
