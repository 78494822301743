.bolt-carousel-item {
  color: rgb(255, 255, 255);
  box-shadow:
    0 0.125rem 0.25rem rgb(0 0 0 / 14%),
    0 0 0.125rem rgb(0 0 0 / 12%);
  outline: none;
  overflow: hidden;
}

.bolt-carousel-item .tile-overlay {
  background: linear-gradient(rgba(0, 0, 0, 0) 0% 60%, rgba(0, 0, 0, 0.501961) 90% 100%);
}
