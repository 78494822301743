.photo-scrubber {
  opacity: 0;
  transition: opacity 250ms ease;
  width: 2.75rem;
}

.photo-scrubber-visible,
.photo-scrubber:hover {
  opacity: 1;
}

.photo-scrubber-bullet {
  background-color: rgb(var(--palette-neutral-20));
  border-radius: 50%;
  height: 0.25rem;
  margin: 2rem 1.125rem;
  width: 0.25rem;
}

.photo-scrubber-channel {
  touch-action: none;
}

.photo-scrubber-target {
  background-color: transparent;
  border-radius: 0.25rem;
  padding-inline-end: 0.625rem;
}

.photo-scrubber-target.transition {
  transition: top 250ms ease;
}

.photo-scrubber-target-date {
  background-color: rgb(var(--palette-neutral-2));
}

.photo-scrubber-mark {
  background-color: var(--communication-background);
  border-radius: 0.125rem;
  height: 0.25rem;
  margin: auto;
  width: 1.25rem;
}

.photo-scrubber-mark.active {
  height: 1.5rem;
  width: 0.5rem;
  margin-inline-end: 0.375rem;
}
