.video {
  object-fit: cover;
  object-position: center;
  outline: none;
  z-index: 0;
}

.video-ttml-overlay {
  text-shadow: 2px 2px 1px black;
}

.video-feedback {
  background-color: rgba(0, 0, 0, 0.54);
  color: rgb(255, 255, 255);
}

.video-controls-overlay {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  transition: opacity 750ms ease 0ms;
}

.video-controls {
  background-color: rgba(0, 0, 0, 0.74);
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  flex-grow: 1;
  margin: 1rem;
  margin-bottom: 2.5rem;
  max-width: 25rem;
  opacity: 0;
  transition: opacity 750ms ease 0ms;
}

.video-controls:hover,
.video-controls.visible {
  opacity: 1;
  transition: opacity 750ms ease 0ms;
}

.video-controls .bolt-button {
  color: rgb(255, 255, 255);
}

.video-volume-slider {
  height: 5rem;
  min-width: 2.5rem;
}

.video-slider .bolt-slider-thumb,
.video-slider .bolt-slider-thumb,
.video-slider:focus-within .bolt-slider-thumb,
.video-slider:hover .bolt-slider-thumb {
  background-color: rgba(255, 255, 255);
  border-color: transparent;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.8);
  padding: 0;
}

.video-slider.bolt-slider:focus .bolt-slider-track {
  border-color: rgb(255, 255, 255);
  border-style: solid;
  border-width: 1px 0;
  height: 0.25rem;
}

.video-slider.bolt-slider:focus .bolt-slider-track-leading {
  border-inline-start-width: 1px;
  border-end-start-radius: 2px;
  border-start-start-radius: 2px;
}

.video-slider.bolt-slider:focus .bolt-slider-track-trailing {
  border-end-end-radius: 2px;
  border-inline-end-width: 1px;
  border-start-end-radius: 2px;
}

.video-time-slider.bolt-slider .bolt-slider-track.pre-value,
.video-time-slider.bolt-slider.bolt-slider-pivot-start:focus-within .bolt-slider-track.pre-value,
.video-time-slider.bolt-slider.bolt-slider-pivot-start:hover .bolt-slider-track.pre-value {
  background-color: var(--status-info-foreground);
}

.video-volume-slider.bolt-slider .bolt-slider-track.post-value,
.video-volume-slider.bolt-slider.bolt-slider-pivot-end:focus-within .bolt-slider-track.post-value,
.video-volume-slider.bolt-slider.bolt-slider-pivot-end:hover .bolt-slider-track.post-value {
  background-color: var(--status-info-foreground);
}
