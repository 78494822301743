.bolt-slider {
  min-height: 0.75rem;
  outline: none;
}

.bolt-slider-track {
  background-color: rgb(var(--palette-neutral-30));
}

.bolt-slider-orient-horizontal .bolt-slider-track {
  height: 0.125rem;
}

.bolt-slider-orient-vertical .bolt-slider-track {
  width: 0.125rem;
}

.bolt-slider-thumb {
  background-color: rgb(var(--palette-neutral-0));
  border: 0.125rem solid rgb(var(--palette-neutral-30));
  border-radius: 50%;
  height: 0.75rem;
  padding: 0.0625rem;
  width: 0.75rem;
}

.bolt-slider-orient-horizontal .bolt-slider-thumb {
  transform: translateX(-50%);
}

.bolt-slider-orient-vertical .bolt-slider-thumb {
  transform: translateY(-50%);
}

html[dir="rtl"] .bolt-slider-orient-horizontal .bolt-slider-thumb {
  transform: translateX(50%);
}

html[dir="rtl"] .bolt-slider-orient-vertical .bolt-slider-thumb {
  transform: translateY(50%);
}

.bolt-slider-orient-horizontal .bolt-slider-track-leading,
.bolt-slider-orient-horizontal .bolt-slider-track-trailing {
  width: 0.375rem;
}

.bolt-slider-orient-vertical .bolt-slider-track-leading,
.bolt-slider-orient-vertical .bolt-slider-track-trailing {
  height: 0.375rem;
}

.bolt-slider:focus .bolt-slider-thumb,
.bolt-slider:hover .bolt-slider-thumb {
  border: 0.125rem solid var(--communication-background);
  padding: 0;
}

.bolt-slider.bolt-slider-pivot-center:focus-within .bolt-slider-track.pre-midpoint.post-value,
.bolt-slider.bolt-slider-pivot-center:focus-within .bolt-slider-track.post-midpoint.pre-value,
.bolt-slider.bolt-slider-pivot-center:hover .bolt-slider-track.pre-midpoint.post-value,
.bolt-slider.bolt-slider-pivot-center:hover .bolt-slider-track.post-midpoint.pre-value {
  background-color: var(--communication-background);
}

.bolt-slider.bolt-slider-pivot-end:focus-within .bolt-slider-track.post-value,
.bolt-slider.bolt-slider-pivot-end:hover .bolt-slider-track.post-value {
  background-color: var(--communication-background);
}

.bolt-slider.bolt-slider-pivot-start:focus-within .bolt-slider-track.pre-value,
.bolt-slider.bolt-slider-pivot-start:hover .bolt-slider-track.pre-value {
  background-color: var(--communication-background);
}
