.beta-callout {
  bottom: 0;
}

.beta-callout .beta-callout-content {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

html[dir="ltr"] .beta-callout {
  right: 2.8125rem;
}

html[dir="rtl"] .beta-callout {
  left: 2.8125rem;
}

@media screen and (max-width: 639px) {
  html[dir="ltr"] .beta-callout {
    right: 1.25rem;
  }

  html[dir="rtl"] .beta-callout {
    left: 1.25rem;
  }
}

.bolt-bubble-beak,
.bolt-bubble-content {
  background-color: var(--communication-background);
  color: var(--text-on-communication-background);
  display: flex;
  flex-direction: column;
}
