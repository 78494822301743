.search-form {
  padding-inline-start: 0.938rem;
}

.search-form .search-text-field {
  background-color: transparent;
  border: none;
  min-width: 0;
  padding: 1px;
}

.search-form .search-text-field input {
  background-color: transparent;
  /* to match ODC styles: */
  padding: 6px 12px 5px;
}

.search-form .search-text-field input::placeholder {
  color: var(--palette-black-alpha-70);
}

.search-form .search-text-field .input-button.bolt-button.icon-only {
  padding: 0.13rem;
}

.search-form .search-icon {
  color: var(--text-primary-color);
  margin-top: 2px;
}

.mobile-layout .search-preview-label,
.search-term-available .search-preview-label {
  display: none;
}

.search-form .search-preview-label {
  font-style: italic;
}
