.page-command-bar {
  margin-top: 0.375rem;
  min-height: 3rem;
}

.page-command-bar-raised {
  background-color: rgb(var(--palette-neutral-0));
  border-bottom-color: transparent;
  padding-bottom: 0;
  padding-top: 0;
  margin-bottom: 0.375rem;
}

.shell-container.background-active .page-command-bar-raised {
  background-color: rgba(var(--palette-neutral-0), 0.5);
}

.desktop-layout .page-command-bar-raised,
.embedded .page-command-bar-raised {
  border-radius: 0.5rem;
}

.page-command-bar-inner {
  margin-bottom: 0.375rem;
}

.page-command-bar-raised .page-command-bar-inner {
  margin-bottom: 0;
}

.page-command-bar .bolt-button {
  font-weight: 400;
}

.page-command-bar .bolt-button:hover:not(.pivot-button):not(.disabled) {
  color: var(--communication-foreground);
}

.page-command-bar .strong-button .bolt-button,
.page-command-bar .strong-button {
  font-weight: 600;
}

.page-command-bar .toggle-navigation {
  margin-inline-start: 0;
}
