.drag-drop-overlay {
  animation: fadeIn 300ms;
  opacity: 0.98;
  background-color: rgb(var(--palette-neutral-2));
}

.upload-target-drop-area .drop-image {
  max-height: 500px;
  max-width: 500px;
}
